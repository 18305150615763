import React, { useContext, useEffect, useState } from "react";
import { formatMonto, getValue } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import RatingComments from "../../components/ratings/RatingComments";
import { SingleClassContext } from "../../context/SingleClassContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import { ReservationsContext } from "../../context/ReservationsContext";
import SingleClassForm from "../../components/schedule/SingleClassForm";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import moment from "moment";
import { WaitlistsContext } from "../../context/WaitlistsContext";

const AdminAsistentes = ({ single_class_id }) => {
  const {
    clase,
    clearClase,
    postPayment,
    eliminarClase,
    getAsistentes,
    updateGuestName,
    setPropiedadClase,
  } = useContext(SingleClassContext);
  const { user } = useContext(AuthContext);
  const { postAttend } = useContext(ReservationsContext);
  const { eliminarReservacion } = useContext(ReservationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { waitlist, getSingleClassWaitlist } = useContext(WaitlistsContext);

  const [view, setView] = useState("reservas");

  useEffect(() => {
    fetchAsistentes();
    return () => {
      clearClase();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAsistentes = () => {
    getSingleClassWaitlist(single_class_id);
    getAsistentes(single_class_id);
  };

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <SingleClassForm
        handleCancel={clearModal}
        modifier={setPropiedadClase}
        handleCallback={fetchAsistentes}
        single_class_id={single_class_id}
      />
    );
  };

  const handleComments = () => {
    modalComponent(
      "Comentarios",
      <RatingComments ratings={clase.asistentes} handleCancel={clearModal} />
    );
  };

  const confirmDeleteClass = () => {
    modalComponent(
      "Eliminar Clase",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la clase {clase.class_type?.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => eliminarClase(single_class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <div className="container-fluid">
        <p>
          ¿Estás segura que deseas cancelar la reservacion de {customer.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            eliminarReservacion(customer.class_reservation_id, () =>
              getAsistentes(single_class_id)
            )
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderClassTypeName = () => {
    if (clase.class_type && clase.class_type !== null) {
      return clase.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (clase.location && clase.location !== null) {
      return clase.location.name;
    }
  };

  const renderButtons = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <div>
          <button className="btn btn-sm btn-outline-dark" onClick={handleEdit}>
            <i className="fa fa-edit me-1"></i> Editar
          </button>
          <button
            className="btn btn-sm btn-outline-danger mx-3"
            onClick={confirmDeleteClass}
          >
            <i className="fa fa-trash me-1"></i> Eliminar
          </button>
        </div>
      );
    }
  };

  const renderRating = () => {
    if (clase.class_stars_average !== null) {
      return (
        <div className="d-flex mb-3">
          <span className="me-2">
            <div>
              {formatMonto(clase.class_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: "12px" }} className="mb-0">
              Clase
            </p>
          </span>
          <span className="me-2">
            <div>
              {formatMonto(clase.coach_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: "12px" }} className="mb-0">
              Coach
            </p>
          </span>
          <button
            key="comments"
            onClick={handleComments}
            className="btn text-dark d-inline-block"
          >
            {formatMonto(clase.comments)}
            <i className="fa fa-comment ms-1"></i>
          </button>
        </div>
      );
    }
    return <p className="small text-muted">Sin calificar</p>;
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4>Datos de Clase</h4>
            <p>
              {moment(getValue(clase, "class_date", "datetime")).format(
                "ddd DD MMM YYYY, HH:mm"
              )}
            </p>
            <p>{renderClassTypeName()}</p>
            <p>{renderLocationName()}</p>
            {renderRating()}
            {renderButtons()}
          </div>
          <div className="col col-md-6"></div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderAsistentes = () => {
    if (clase && clase !== null && view === "reservas") {
      return clase.asistentes;
    }
    if (view === "waitlist") {
      return waitlist.map(({ customer }) => customer);
    }
    return [];
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title="Clase Presencial" onClick={addAsistente} />
      <div className="card p-3 shadow-sm no-scale">{renderClase()}</div>
      <div className="btn-group w-100 mt-3">
        <div
          onClick={() => setView("reservas")}
          className={`btn btn-${
            view === "reservas" ? "primary" : "outline-primary"
          }`}
        >
          Reservas
        </div>
        <div
          onClick={() => setView("waitlist")}
          className={`btn btn-${
            view === "waitlist" ? "primary" : "outline-primary"
          }`}
        >
          Lista de Espera
        </div>
      </div>
      <AsistentesTable
        asistentes={renderAsistentes()}
        is_waitlist={view === "waitlist"}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        handleCallback={fetchAsistentes}
        confirmCancel={confirmCancel}
        postPayment={postPayment}
        postAttend={postAttend}
        user={user}
      />
    </div>
  );
};

export default AdminAsistentes;
