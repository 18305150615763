import React, { useContext, useEffect, useState } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { CoachesContext } from "../../context/CoachesContext";
import { LocationsContext } from "../../context/LocationsContext";
import { PackagesContext } from "../../context/PackageContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import DateTimePicker from "../common/DateTimePicker";
import SelectInstructors from "../global/SelectInstructors";
import { getValue } from "../../utils";

const SingleClassForm = ({
  modifier,
  handleCancel,
  handleCallback,
  single_class_id,
}) => {
  const [current_id, setCurrent_id] = useState(null);
  const [first, setFirst] = useState(false);

  const {
    clase,
    getClase,
    postClase,
    createClase,
    addInstructor,
    removeInstructor,
  } = useContext(SingleClassContext);
  const { coaches, getCoaches } = useContext(CoachesContext);
  const { locations, getLocations } = useContext(LocationsContext);
  const { paquetes, getAllPaquetes } = useContext(PackagesContext);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getAllPaquetes();
    getCurrenClase();
    getClassTypes();
    getLocations();
    getCoaches();
    return () => {
      setFirst(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!first && clase !== null) {
      if (Array.isArray(clase.class_instructors)) {
        if (clase.class_instructors.length > 0) {
          modifier("instructor_id", clase.class_instructors[0].instructor_id);
        }
      }
      setFirst(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clase]);

  useEffect(() => {
    getCurrenClase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  const getCurrenClase = () => {
    if (!isNaN(single_class_id)) {
      getClase(single_class_id);
    }

    if (clase === null || clase === undefined) {
      createClase();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postClase(clase, handleCallback);
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      if (locations.length > 0 && clase.location_id === "") {
        setTimeout(() => {
          modifier("location_id", locations[0].location_id);
          modifier("branch_id", locations[0].branch_id);
        }, 100);
      }

      return locations.map((location) => (
        <option key={location.location_id} value={location.location_id}>
          {location.name}
        </option>
      ));
    }
  };

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      if (class_types.length > 0 && clase.class_type_id === "") {
        setTimeout(() => {
          modifier("class_type_id", class_types[0].class_type_id);
        }, 100);
      }
      return class_types.map((class_type) => (
        <option key={class_type.class_type_id} value={class_type.class_type_id}>
          {class_type.name}
        </option>
      ));
    }
  };

  const renderPaquetes = () => {
    if (Array.isArray(paquetes)) {
      return [{ title: "No es evento especial", class_package_id: null }]
        .concat(paquetes)
        .map((paquete) => (
          <option
            key={paquete.class_package_id}
            value={paquete.class_package_id}
          >
            {paquete.title}
          </option>
        ));
    }
  };

  const renderForm = () => {
    if (clase && clase !== null) {
      const class_type_id = getValue(clase, "class_type_id");

      return (
        <form onSubmit={handleSubmit}>
          <label>Tipo de Clase</label>
          <div className="row">
            <div className="col col-md-6">
              <select
                className="form-control mb-3"
                value={class_type_id}
                onChange={(e) => modifier("class_type_id", e.target.value)}
              >
                {renderClassTypes()}
              </select>
            </div>
            <div className="col col-md-6"></div>
          </div>
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(clase, "description")}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <DateTimePicker
            value={getValue(clase, "class_date", "datetime")}
            modifier={(value) => modifier("class_date", value)}
          />
          <SelectInstructors
            instructors={coaches}
            addInstructor={addInstructor}
            removeInstructor={removeInstructor}
            selected={clase.class_instructors}
          />
          <label>Ubicación</label>
          <div className="row">
            <div className="col col-md-6">
              <select
                className="form-control mb-3"
                value={getValue(clase, "location_id")}
                onChange={(e) => {
                  modifier("location_id", parseInt(e.target.value));
                }}
              >
                {renderLocations()}
              </select>
            </div>
            <div className="col col-md-6"></div>
          </div>
          <label>Capacidad</label>
          <input
            type="number"
            min={0}
            className="form-control mb-3"
            value={getValue(clase, "capacity")}
            onChange={(e) => modifier("capacity", e.target.value)}
          />
          <label>Evento Especial</label>
          <select
            className="form-control mb-3"
            value={getValue(clase, "class_package_id")}
            onChange={(e) => modifier("class_package_id", e.target.value)}
          >
            {renderPaquetes()}
          </select>
          <div className="row">
            <div className="col col-md-6">
              <button type="submit" className="btn w-100 btn-primary">
                Guardar
              </button>
            </div>
            <div className="col col-md-6 text-end">
              <button
                type="button"
                onClick={handleCancel}
                className="btn w-100 text-muted"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default SingleClassForm;
