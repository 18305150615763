import React, { createContext, useContext, useReducer } from "react";
import ClassInstructorReducer from "../reducers/ClassInstructorReducer";
import ClassInstructorService from "../services/ClassInstructorService";
import {
  CREATE_CLASE,
  SEMANAS_RECIBIDAS,
  SET_CLASE,
  SET_PROPIEDAD_CLASE,
  CLASES_RECIBIDAS,
  TOTAL_RECIBIDO,
  SHOW_SPINNER,
  HIDE_SPINNER,
  ADD_INSTRUCTOR,
  REMOVE_INSTRUCTOR,
  SET_MONTH,
  SET_WEEK,
  SET_START_DATE,
  SET_END_DATE,
  SET_VIEW,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  instructorClass: null,
  class_types: null,
  spinner: false,
  view: "month",
  clases: null,
  clase: null,
  weeks: null,
  month: null,
};

export const SingleClassContext = createContext(initialState);

export const ClassInstructorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassInstructorReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getClases = (start_date, end_date) => {
    dispatch({ type: SHOW_SPINNER });
    ClassInstructorService.getClases(start_date, end_date).then((res) => {
      const { clases, total } = res.data;
      dispatch({ type: CLASES_RECIBIDAS, payload: clases });
      dispatch({ type: TOTAL_RECIBIDO, payload: total });
      dispatch({ type: HIDE_SPINNER });
    });
  };

  const getAsistentes = (class_instructor_id) => {
    ClassInstructorService.getAsistentes(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const getSchedule = (filters) => {
    ClassInstructorService.getWeeks(filters).then((res) => {
      const { classes } = res.data;
      dispatch({ type: SEMANAS_RECIBIDAS, payload: classes });
    });
  };

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const setView = (view) => {
    dispatch({ type: SET_VIEW, payload: view });
  };

  const getClase = (class_instructor_id) => {
    ClassInstructorService.getClase(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const createClase = () => {
    dispatch({ type: CREATE_CLASE });
  };

  const setClase = (clase) => {
    dispatch({ type: SET_CLASE, payload: clase });
  };

  const setPropiedadClase = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CLASE, payload: { key, value } });
  };

  const addInstructor = (instructor) => {
    dispatch({ type: ADD_INSTRUCTOR, payload: instructor });
  };

  const removeInstructor = (instructor_id) => {
    dispatch({ type: REMOVE_INSTRUCTOR, payload: instructor_id });
  };

  const postClase = (clase, callback) => {
    const handleSuccess = () => {
      success("¡Clase guardada con éxito!");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    };
    if (isNaN(clase.single_class_id)) {
      ClassInstructorService.postClase(clase).then(handleSuccess);
    } else {
      ClassInstructorService.putClase(clase).then(handleSuccess);
    }
  };

  const clearClase = () => {
    dispatch({ type: SET_CLASE, payload: null });
  };

  const eliminarClase = (single_class_id) => {
    ClassInstructorService.deleteClase(single_class_id).then(() => {
      success("¡Clase eliminada con éxito!");
      getSchedule();
      hideModal();
    });
  };

  const postAsistenteClase = (
    customer_id,
    class_instructor_id,
    payment_method_id,
    is_paid
  ) => {
    ClassInstructorService.postAsistenteClase(
      customer_id,
      class_instructor_id,
      payment_method_id,
      is_paid
    )
      .then(() => {
        getAsistentes(class_instructor_id);
        success("¡Asistente agregado!");
        hideModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo sentimos, esta clase ya esta llena.");
          }
        }
      });
  };

  const postPayment = (class_reservation_id, is_paid, class_instructor_id) => {
    ClassInstructorService.postPayment(class_reservation_id, is_paid).then(
      () => {
        success(is_paid ? "Pago registrado." : "Pago cancelado.");
        getAsistentes(class_instructor_id);
      }
    );
  };

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  const clearSemanas = () => {
    dispatch({ type: SEMANAS_RECIBIDAS, payload: null });
  };

  return (
    <SingleClassContext.Provider
      value={{
        ...state,
        setView,
        setWeek,
        setMonth,
        setClase,
        getClase,
        getClases,
        postClase,
        clearClase,
        setEndDate,
        postPayment,
        getSchedule,
        createClase,
        clearSemanas,
        setStartDate,
        addInstructor,
        eliminarClase,
        getAsistentes,
        removeInstructor,
        setPropiedadClase,
        postAsistenteClase,
      }}
    >
      {children}
    </SingleClassContext.Provider>
  );
};
