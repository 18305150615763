import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import SingleClassForm from "./SingleClassForm";
import { Link } from "@reach/router";
import moment from "moment";
import { formatMonto } from "../../utils";
import { SingleClassContext } from "../../context/SingleClassContext";

const ScheduleAdminClass = ({ singleClass, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setPropiedadClase, eliminarClase } = useContext(SingleClassContext);

  const { user } = useContext(AuthContext);

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <SingleClassForm
        single_class_id={singleClass.single_class_id}
        handleCallback={handleCallback}
        modifier={setPropiedadClase}
        handleCancel={clearModal}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar la clase de{" "}
          {singleClass.class_type.name} del{" "}
          {moment(singleClass.class_date).format("DD MMM YYY")}?
        </p>
        <button
          className="btn btn-danger btn-sm"
          onClick={() =>
            eliminarClase(singleClass.single_class_id, handleCallback)
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderButtons = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <div>
          <button className="btn btn-primary btn-sm mx-2" onClick={handleEdit}>
            <i className="fa fa-edit" />
          </button>
          <button
            className="btn btn-outline-danger btn-sm mx-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    }
  };

  const renderInstructors = () => {
    return singleClass.class_instructors.map((class_instructor) => (
      <span
        className="badge badge-pill me-1 bg-secondary"
        key={class_instructor.instructor_id}
      >
        {class_instructor.instructor.name}
      </span>
    ));
  };

  const renderRating = () => {
    let components = [];
    if (singleClass.class_stars_average !== null) {
      components.push(
        <span key="class" className="me-3">
          <div>
            {formatMonto(singleClass.coach_stars_average)}{" "}
            <i className="fa text-warning fa-star ms-1"></i>
          </div>
          <p style={{ fontSize: "12px" }} className="mb-0">
            Clase
          </p>
        </span>
      );
    }
    if (singleClass.coach_stars_average !== null) {
      components.push(
        <span key="coach">
          <div>
            {formatMonto(singleClass.coach_stars_average)}
            <i className="fa text-warning fa-star ms-1"></i>
          </div>
          <p style={{ fontSize: "12px" }} className="mb-0">
            Coach
          </p>
        </span>
      );
    }
    if (components.length === 0) {
      components.push(
        <span key="none" className="small text-muted">
          Sin calificar
        </span>
      );
    }
    return (
      <div className="mb-2 d-flex justify-content-evenly">{components}</div>
    );
  };

  const renderLocation = () => {
    if (singleClass?.location?.name) {
      return `${singleClass.location.name}`;
    }
  };

  return (
    <div className="schedule-class border border-dark py-3 px-2 m-2">
      <p className="mb-1 bold">
        <i className={singleClass.icon} /> {singleClass.class_type.name}
      </p>
      <p className="small mb-1 small">{renderLocation()}</p>
      <p className="small font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      <div className="mb-2">{renderInstructors()}</div>
      <Link
        className="mb-2 text-primary d-block"
        to={`/myadmin/asistentes/${singleClass.single_class_id}`}
      >
        <i className="fa fa-user me-1" /> {singleClass.reservations} /{" "}
        {singleClass.capacity}
      </Link>
      {renderRating()}
      {renderButtons()}
    </div>
  );
};

export default ScheduleAdminClass;
