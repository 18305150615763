import moment from "moment";
import React from "react";
import { Link } from "@reach/router";
import useBranch from "../../hooks/useBranch";

const AsistenteRow = ({
  user,
  asistente,
  postAttend,
  postPayment,
  is_waitlist,
  confirmCancel,
  handleCallback,
  single_class_id,
  is_special_event,
}) => {
  const { branch } = useBranch();

  const sendWhatsApp = (telefono) => {
    telefono = String(telefono).replace("+52", "");
    window.open(`https://wa.me/521${telefono}`, "_blank");
  };

  const renderCustomerName = () => {
    if (asistente.customer && asistente.customer !== null) {
      return (
        <span>
          {asistente.customer.name} {asistente.customer.last_name}
        </span>
      );
    }
    return (
      <span>
        {asistente.name} {asistente.last_name}
      </span>
    );
  };

  const isTrialClass = () => {
    const reservation = asistente;
    const { available_class } = reservation;
    if (available_class && available_class !== null) {
      const { purchase } = available_class;
      if (purchase && purchase !== null) {
        const class_package_id = branch?.branch_id === 1 ? 19304 : 19301;
        return purchase.class_package_id === class_package_id;
      }
    }
  };

  const renderCustomer = () => {
    const name = renderCustomerName();
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <Link to={`/myadmin/customer/${asistente.customer_id}`}>
          {name}{" "}
          {isTrialClass() && (
            <span className="badge badge-pill bg-primary ms-2">
              <i className="fa fa-award me-1" /> Trial Class
            </span>
          )}
        </Link>
      );
    }
    return (
      <span>
        {name}{" "}
        {isTrialClass() && (
          <span className="badge badge-pill bg-primary ms-2">
            <i className="fa fa-award me-1" /> Trial Class
          </span>
        )}
      </span>
    );
  };

  const renderBirthday = () => {
    if (asistente.birthdate && asistente.birthdate !== null) {
      if (
        moment(asistente.birthdate).utc().format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
      ) {
        return <i className="fa fa-birthdate-cake"></i>;
      }
    }
  };

  const renderCustomerContact = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return [
        <td key="instagram">
          <i className="fab fa-instagram me-2"></i>
          {"@"}
          {asistente.instagram}
        </td>,
        <td key="email">
          <i className="fa fa-envelope me-2"></i>
          {asistente.email}
        </td>,
        <td key="whatsapp">
          <button
            className="me-2 btn-sm btn btn-success"
            onClick={() => sendWhatsApp(asistente.phone)}
          >
            <i className="fab fa-whatsapp"></i>
          </button>
          {asistente.phone}
        </td>,
      ];
    }
  };

  const renderCancelButton = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <button
          className="btn btn-sm btn-outline-danger mx-2"
          onClick={() => confirmCancel(asistente)}
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };

  return (
    <tr className="align-middle small bg-white">
      <td>
        {renderBirthday()}
        {renderCustomer()}
      </td>
      {renderCustomerContact()}
      {!is_special_event && !is_waitlist && (
        <td>
          <button
            className={`btn btn-sm btn-${
              !asistente.attend ? "outline-secondary" : "link text-dark"
            } me-2`}
            onClick={() =>
              postAttend(
                asistente.class_reservation_id,
                !asistente.attend,
                handleCallback
              )
            }
          >
            <i className="fa fa-check"></i>
          </button>
          {asistente.is_cash && (
            <button
              className={`btn btn-sm btn-outline-${
                asistente.is_paid ? "danger" : "success"
              } mx-2`}
              onClick={() =>
                postPayment(
                  asistente.class_reservation_id,
                  !asistente.is_paid,
                  single_class_id
                )
              }
            >
              <i className="fa fa-money-bill"></i>
            </button>
          )}
          {renderCancelButton()}
        </td>
      )}
    </tr>
  );
};

export default AsistenteRow;
